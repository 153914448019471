import { Component, OnInit } from '@angular/core';
// import { ServerCommunicationsService } from '../services/serverCommunications.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent {

  // public version: string;

  // constructor(private serverCommunicationsService: ServerCommunicationsService,) { 
  //   this.getVersion();

  // }

  // ngOnInit(): void {
  // }

  // public getVersion() {
  //   this.serverCommunicationsService.getVersion().subscribe(data => {
  //     this.version = data;
  //   });
  // }

}
